<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="my-3">
    <div class="d-flex flex-row align-center justify-space-between mb-4">
      <div class="top-header mb-0 font-weight-bold">
        {{ "Backups" }}
      </div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="primary" icon @click="getBackups"
            ><v-icon>mdi-reload</v-icon></v-btn
          >
        </template>
        <span>Reload</span>
      </v-tooltip>
    </div>

    <template>
      <v-data-table
        :headers="headers"
        :server-items-length="total"
        :options.sync="options"
        :items="backups"
        :loading="loading"
        :footer-props="footerProps"
        class="elevation-1"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          <span>{{ getDate(item.createdAt) }} </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end px-4">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  small
                  icon
                  color="primary"
                  @click="toRestore(item)"
                  ><v-icon>mdi-backup-restore</v-icon></v-btn
                >
              </template>

              <span>Restore Backup</span>
            </v-tooltip>
            <template v-if="isSuper || isAdmin">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    small
                    icon
                    @click="toDelete(item)"
                    color="error"
                    ><v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>

                <span>Delete Backup</span>
              </v-tooltip>
            </template>
          </div>
        </template>
      </v-data-table>

      <!--  Delete Backup -->
      <v-dialog
        v-model="deleteBackup.dialog"
        persistent
        :overlay="false"
        max-width="300px"
        transition="dialog-transition"
      >
        <v-card>
          <v-toolbar dark color="error"> Delete Backup </v-toolbar>
          <v-card-text class="mt-3">
            <div class="pa-2 text-center">Are you sure ?</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelDelete">Cancel</v-btn>
            <v-btn
              text
              :loading="deleteBackup.loading"
              @click="confirmDelte"
              color="error"
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!--  Restore Backups -->
      <v-dialog
        v-model="restoreBackup.dialog"
        persistent
        :overlay="false"
        max-width="300px"
        transition="dialog-transition"
      >
        <v-card>
          <v-toolbar dark color="primary"> Restore Backup </v-toolbar>
          <v-card-text class="mt-3">
            <div class="pa-2 text-center">Are you sure ?</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelDelete" color="error">Cancel</v-btn>
            <v-btn
              text
              :loading="restoreBackup.loading"
              @click="confirmRestore"
              color="primary"
              >Restore</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { utilMixin } from "@/utils/mixins";
import { mapGetters } from "vuex";
import { notifyError } from "@/components/Notification";
export default {
  name: "backups-imports",
  mixins: [utilMixin],
  data() {
    return {
      headers: [
        {
          text: "Create At",
          align: "start",
          sortable: false,
          value: "createdAt",
        },
        {
          text: "Status",

          sortable: false,
          value: "status",
        },
        {
          text: "",

          sortable: false,
          value: "actions",
        },
      ],
      total: 0,
      loading: false,
      backups: [],
      deleteBackup: {
        dialog: false,
        loading: false,
        item: null,
      },
      restoreBackup: {
        dialog: false,
        loading: false,
        item: null,
      },
      options: {},
      footerProps: {
        "items-per-page-options": [15, 50, 100, 500],
      },
    };
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getBackups();
      }
    },
  },
  computed: {
    ...mapGetters(["isSupervisor", "isAdmin", "isSuper"]),
  },
  methods: {
    getBackups() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10;
      const body = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };

      getAPI
        .post("/transactions/list", body)
        .then((res) => {
          this.backups = res.data.result;
          this.total = res.data.count;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;

          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    toRestore(item) {
      this.restoreBackup = {
        dialog: true,
        loading: false,
        item: item,
      };
    },
    toDelete(item) {
      this.deleteBackup = {
        dialog: true,
        loading: false,
        item: item,
      };
    },
    confirmDelte() {
      this.deleteBackup.loading = true;
      getAPI
        .delete("/transactions/delete/" + this.deleteBackup.item.uuid)
        .then((res) => {
          this.deleteBackup.loading = false;
          this.cancelDelete();
          this.getBackups();
        })
        .catch((err) => {
          this.deleteBackup.loading = false;
          let mess = err.response.data.message.toString();
          notifyError(err.response.data, `An error occurred: ${mess}`);
        });
    },
    confirmRestore() {
      this.restoreBackup.loading = true;
      getAPI
        .put("/transactions/restore/" + this.restoreBackup.item.uuid)
        .then((res) => {
          this.restoreBackup.loading = false;
          this.cancelDelete();
          this.getBackups();
        })
        .catch((err) => {
          this.restoreBackup.loading = false;
          let mess = err.response.data.message.toString();
          notifyError(err.response.data, `An error occurred: ${mess}`);
        });
    },
    cancelDelete() {
      this.deleteBackup = {
        dialog: false,
        loading: false,
        item: null,
      };
      this.restoreBackup = {
        dialog: false,
        loading: false,
        item: null,
      };
    },
  },
};
</script>
<style></style>
